import { buildCollection } from "@camberi/firecms";

type Project = {
    title: string;
    description: string;
    images: string[];
    order: number;
    isFeatured: boolean;
    featuredImage: string;
}

export const projectCollection = buildCollection<Project>({
    name: "Projekti",
    singularName: "Projekt",
    path: "projects",
    icon: 'AirportShuttle',
    group: 'Vsebina',
    inlineEditing: false,
    initialSort: ["order", "desc"],
    defaultSize: "xl",
    properties: {
        title: {
            name: "Naslov",
            validation: {required: true},
            dataType: "string"
        },
        description: {
            name: "Opis projekta",
            validation: {required: true},
            markdown: true,
            dataType: "string"
        },
        featuredImage: {
            dataType: "string",
            name: "Naslovna slika projekta",
            description: "Slika projekta, ki bo služila kot prikazna slika za projekt.",
            validation: {required: true},
            storage: {
                storagePath: "projects/images",
                acceptedFiles: ["image/*"],
                metadata: {
                    cacheControl: "max-age=1000000"
                }
            }
        },
        images: {
            dataType: "array",
            name: "Galerija slik",
            validation: {required: true},
            description: "Galerija slik",
            of: {
                dataType: "string",
                storage: {
                    storagePath: "projects/images",
                    acceptedFiles: ["image/*"],
                    metadata: {
                        cacheControl: "max-age=1000000"
                    }
                }
            }
        },
        order: {
            dataType: "number",
            name: "Vrstni red",
            validation: {required: true},
            description: "Vrstni red projekta. Projekti so razvrščeni po vrtnem redu. Največja številka predstavlja najnovejši projekt, najmanjša pa najstarejši projekt."
        },
        isFeatured: {
            dataType: "boolean",
            name: "Izpostavi projekt",
            description: "Izpostavljeni projekti bodo prikazani na naslovni strani. Maksimalno število izpostavljenih elementov je 3."
        }
    }
});

type Service = {
    title: string;
    description: string;
    order: number;
}

export const servicesCollection = buildCollection<Service>({
    name: "Storitve",
    singularName: "Storitev",
    path: "services",
    icon: 'Construction',
    group: 'Vsebina',
    initialSort: ["order", "desc"],
    inlineEditing: false,
    defaultSize: "xl",
    properties: {
        title: {
            name: "Naslov",
            validation: {required: true},
            dataType: "string"
        },
        description: {
            name: "Opis storitve",
            validation: {required: true},
            markdown: true,
            dataType: "string"
        },
        order: {
            dataType: "number",
            name: "Vrstni red",
            validation: {required: true},
            description: "Storitve so razvrščene po vrstnem redu. Največja številka predstavlja storitev prikazano na vrhu, najmanjša pa storitev na dnu."
        },
    }
});

export type ContactInfo = {
    email: string;
    phone: string;
    address: string;
    city: string;
    workingHours: string;
    facebookUrl: string;
    facebookName: string;
    instagramUrl: string;
}

export const MAIN_CONTACT_ENTITY_ID = "main-contact-info";
export const contactInfoCollection = buildCollection<ContactInfo>({
    name: "Kontakt",
    path: "/contactInfo",
    icon: "ContactPage",
    group: 'Vsebina',
    hideFromNavigation: true,
    properties: {
        email: {
            name: "Email",
            validation: {required: true},
            email: true,
            dataType: "string"
        },
        phone: {
            name: "Telefon",
            validation: {required: true},
            dataType: "string",
            description: "Npr.: +38641123456"
        },
        address: {
            name: "Ulica in hišna številka",
            validation: {required: true},
            dataType: "string",
        },
        city: {
            name: "Poštna številka in mesto",
            validation: {required: true},
            dataType: "string",
            description: "Npr.: 1000 Ljubljana"
        },
        workingHours: {
            name: "Delovni čas",
            validation: {required: true},
            dataType: "string",
            description: "Npr.: Pon. - Pet.: od 7:00 do 16:00"
        },
        facebookUrl: {
            name: "Facebook URL",
            validation: {required: true},
            url: true,
            description: "Povezava do facebook strani.",
            dataType: "string"
        },
        facebookName: {
            name: "Facebook Prikazno Ime",
            validation: {required: true},
            description: "Ime facebook strani.",
            dataType: "string"
        },
        instagramUrl: {
            name: "Instagram URL",
            validation: {required: true},
            url: true,
            description: "Povezava do instagram strani.",
            dataType: "string"
        }
    }
});
