import React from "react";
import { useEntityFetch, User, useSideEntityController } from "@camberi/firecms";
import { Card, CardContent, CardHeader, Grid, IconButton, Link, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { ContactInfo, contactInfoCollection, MAIN_CONTACT_ENTITY_ID } from "../collections/collections";

export function ContactInfoView() {
    const sideEntityController = useSideEntityController();
    const contactInfoUrlPath = "/contactInfo";

    const currentContactInfo = useEntityFetch<ContactInfo, User>({
        path: contactInfoUrlPath,
        entityId: MAIN_CONTACT_ENTITY_ID,
        collection: contactInfoCollection
    });

    return (
        <Grid justifyContent={'center'} container>
            <Grid item>
                <Card sx={{m: 5}}>
                    <CardHeader
                        action={<IconButton aria-label="edit" size="small" onClick={() => sideEntityController.open({
                            entityId: MAIN_CONTACT_ENTITY_ID,
                            path: contactInfoUrlPath,
                            collection: contactInfoCollection
                        })}>
                            <EditIcon fontSize="inherit"/>
                        </IconButton>}
                        title="Kontakt"/>
                    <CardContent>
                        <Grid container direction={'column'} spacing={2}>
                            <Grid item>
                                <Typography variant={'body1'} fontWeight={'bold'}>Email</Typography>
                                <Typography variant={'body1'}>{currentContactInfo.entity?.values.email}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={'body1'} fontWeight={'bold'}>Telefon</Typography>
                                <Typography variant={'body1'}>{currentContactInfo.entity?.values.phone}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={'body1'} fontWeight={'bold'}>Naslov</Typography>
                                <Typography
                                    variant={'body1'}>{currentContactInfo.entity?.values.address}, {currentContactInfo.entity?.values.city}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={'body1'} fontWeight={'bold'}>Delovni čas</Typography>
                                <Typography
                                    variant={'body1'}>{currentContactInfo.entity?.values.workingHours}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={'body1'} fontWeight={'bold'}>Facebook URL</Typography>
                                <Link href={currentContactInfo.entity?.values.facebookUrl ?? ''}
                                      target={"_blank"}><Typography
                                    variant={'body1'}>{currentContactInfo.entity?.values.facebookUrl}</Typography></Link>
                            </Grid>
                            <Grid item>
                                <Typography variant={'body1'} fontWeight={'bold'}>Facebook Ime</Typography>
                                <Typography
                                    variant={'body1'}>{currentContactInfo.entity?.values.facebookName}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={'body1'} fontWeight={'bold'}>Facebook URL</Typography>
                                <Link href={currentContactInfo.entity?.values.instagramUrl ?? ''}
                                      target={"_blank"}><Typography
                                    variant={'body1'}>{currentContactInfo.entity?.values.instagramUrl}</Typography></Link>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
