export const firebaseConfig = {
    apiKey: "AIzaSyA7ba6UK_hjM4JjZPFBaHSzxCIuBJFh_nQ",
    authDomain: "zvan-si.firebaseapp.com",
    projectId: "zvan-si",
    storageBucket: "zvan-si.appspot.com",
    messagingSenderId: "468551809822",
    appId: "1:468551809822:web:5f4af2e9be0db010d1270f",
    measurementId: "G-9QP1MK9JWW"
};

export const DEPLOY_HOOK_URL = 'https://api.cloudflare.com/client/v4/pages/webhooks/deploy_hooks/5e000b4c-25a5-4423-ad6c-e1a83e82e54a';
