import React, { useCallback } from "react";

import { User as FirebaseUser } from "firebase/auth";
import { Authenticator, buildCollection, CMSView, FirebaseCMSApp } from "@camberi/firecms";

import "typeface-rubik";
import "@fontsource/ibm-plex-mono";

import zvanCmsLogo from './assets/zvan-cms-logo.png'
import PublishView from "./components/PublishView";
import { ContactInfoView } from "./components/ContactInfoView";
import { DEPLOY_HOOK_URL, firebaseConfig } from "./env";
import { projectCollection, servicesCollection } from "./collections/collections";

export default function App() {
    const myAuthenticator: Authenticator<FirebaseUser> = useCallback(async ({
                                                                                user,
                                                                                authController
                                                                            }) => {
        return true;
    }, []);

    const customViews: CMSView[] = [{
        path: "publish",
        name: "Objavi vsebino",
        icon: 'Publish',
        group: 'Objava vsebine',
        view: <PublishView
            deployHookUrl={DEPLOY_HOOK_URL}/>
    }, {
        path: "contact-info",
        name: "Kontakt",
        icon: 'ContactPage',
        group: 'Vsebina',
        view: <ContactInfoView/>
    }];

    return <FirebaseCMSApp
        name={'zvan.si'}
        authentication={myAuthenticator}
        views={customViews}
        collections={[projectCollection, servicesCollection]}
        firebaseConfig={firebaseConfig}
        signInOptions={['password']}
        logo={zvanCmsLogo}
    />;
}
