import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Alert, LoadingButton } from "@mui/lab";

export default function PublishView({deployHookUrl}: { deployHookUrl: string }) {
    const [contentPublished, setContentPublished] = useState(false);
    const [publishSuccessful, setPublishSuccessful] = useState(true);
    const [isPublishing, setIsPublishing] = useState(false);
    const publishContent = () => {
        setIsPublishing(true);
        fetch(deployHookUrl, {method: 'POST', mode: 'no-cors'})
            .then(() => setPublishSuccessful(true))
            .catch(() => setPublishSuccessful(false))
            .finally(() => {
                setIsPublishing(false);
                setContentPublished(true);
            });
    };
    return <Grid direction={'column'} spacing={2} container alignItems={'center'}>
        <Grid item>
            <Typography variant={'body1'} sx={{m: 2}}>S klikom na spodnji gumb, bo vsebina objavljena na spletni strani
                in na voljo v roku nekaj minut.</Typography>
        </Grid>
        <Grid item>
            {contentPublished ?
                publishSuccessful ?
                    <Alert severity="success">Vsebina uspesno objavljena. Vsebina bo na voljo v roku nekaj
                        minut.</Alert> :
                    <Alert severity="error">Prišlo je do napake in vsebina ni bila uspesno objavljena.</Alert> :
                <LoadingButton variant={'contained'} size={'large'} loading={isPublishing} onClick={publishContent}>Objavi
                    vsebino</LoadingButton>}
        </Grid>
    </Grid>;
}
